















import {Component, Vue} from "vue-property-decorator";
import RouteNames from "@/router/RouteNames";
import {namespace} from "vuex-class";
import UserDTO from "@/dto/UserDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component
export default class IndividualPayroll extends Vue {

  @AppModule.State
  private isMobile!: boolean;

  @Auth.State
  private user!: UserDTO;

  goToPaystubs() {
    this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL_PAYSTUBS, params: {personId: this.$route.params.personId}});
  }

  goToForms() {
    this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL_FORMS, params: {personId: this.$route.params.personId}});
  }

  goToFederalIncomeTaxReturn() {
    this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_FEDERAL, params: {personId: this.$route.params.personId}});
  }


  goToStateIncomeTaxReturn() {
    this.$router.push({name: RouteNames.PAYROLL_INDIVIDUAL_INCOME_TAX_STATE, params: {personId: this.$route.params.personId}});
  }

}
